/* General Styling */
body {
  font-family: 'Libre Franklin', sans-serif;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
}
.success-message {
  color: green;
}

.error-message {
  color: red;
}

.contact-form {
  position: relative;
  top: -20px; /* Moves the form up */
}
.contact-form h2 {
  color: #013d79; /* Blue color for the heading */
  font-size: 2rem !important;
  
}
.col-md-6 {
  padding-top: 0; /* Remove any extra padding that might be pushing the form down */
  font-family: "CALISTO MT", serif;
  
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6px;
}

/* Navbar */
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  background-color: #fff; /* Ensure navbar remains unaffected */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0; /* Remove all padding */
  height: auto; /* Let the height adjust to content */
  line-height: 1; /* Minimize line height */
}

/* Digital Marketing Page */
.digital-marketing-page {
  font-family: "CALISTO MT", serif;
  background-image: url('https://wallpapers.com/images/featured/blank-white-7sn5o1woonmklx1h.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 50px 0;
  min-height: 100vh;
  margin-top: 1rem;
}

h1, h2, h3 {
  color: #0f0f10ef;
  font-weight: bold;
}

/* Digital Marketing Page Styles */
.digital-marketing-heading {
  font-family: "CALISTO MT", serif;
  color: #013d79;
  margin-bottom: 20px;
  margin-top: -1rem;
}
.digital-marketing-page p {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  font-size: 1.1rem;
  
  
}



/* Scoped SEO Box Styling */
.col-md-6 .info-box {
  background-color: #f8f9fa; /* Light background color */
  padding: 20px; /* Padding around content */
  margin-bottom: 20px; /* Space below the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* Ensures proper layering */
  overflow: hidden; /* Clips the image to fit inside the box */
}

.col-md-6 .info-box img.info-image {
  width: 100%; /* Full width of the container */
  height: 266px; /* Fixed height to make the image prominent */
  object-fit: cover; /* Ensures the image fits nicely inside */
  display: block; /* Ensures no extra space below the image */
  margin: 0 auto; /* Center alignment */
}

.col-md-6 .info-box h3 {
  font-family: "CALISTO MT", serif;
  color: #013d79; /* Matching heading color */
  margin-top: 15px; /* Space between image and heading */
}

.col-md-6 .info-box p {
  font-family: 'Source Sans Pro', sans-serif;
  color: #333; /* Text color */
  font-size: 1.1rem;
  line-height: 1.5; /* Better readability */
}
/* Three Horizontal Boxes Styling */
.row .col-md-4 .info-box {
  background-color: #f8f9fa; /* Light background color */
  padding: 20px; /* Padding inside the box */
  margin-bottom: 20px; /* Space between rows */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  text-align: center; /* Center-align the content */
  overflow: hidden; /* Ensures content stays inside the box */
}

.row .col-md-4 .info-box img.info-image {
  width: 100%; /* Image spans full width of the container */
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Keeps image proportionate within the box */
  border-radius: 7px; /* Slightly rounded corners for images */
  margin-bottom: 15px; /* Space between image and heading */
}

.row .col-md-4 .info-box h3 {
  font-family:  'Libre Franklin', sans-serif;
  color: #013d79; /* Blue color for headings */
  font-size: 1.4rem; /* Slightly larger font size for emphasis */
  margin-bottom: 10px; /* Space below the heading */
}

.row .col-md-4 .info-box p {
  font-family: 'Source Sans Pro', sans-serif;
  color: #333; /* Text color */
  font-size: 1rem; /* Slightly smaller font size */
  line-height: 1.6; /* Improved readability */
}

/* Responsive Design */
@media (max-width: 768px) {
  .row .col-md-4 .info-box img.info-image {
    height: 150px; /* Adjust image height for smaller screens */
  }

  .row .col-md-4 .info-box h3 {
    font-size: 1.2rem; /* Slightly smaller heading font size */
  }

  .row .col-md-4 .info-box p {
    font-size: 0.9rem; /* Slightly smaller text font size */
  }
  
  
  
}


/* Cutting-Edge Section */
.cutting-edge-container {
  position: relative;
  text-align: center;
  padding: 50px 20px;
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 100vh; /* Ensures the container is at least the height of the viewport */
  overflow: hidden; /* Prevents overflow */
  background-color: rgb(169, 163, 211);
  
}



.cutting-edge-title {
  font-family: "CALISTO MT", serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #013d79;
  z-index: 1; /* Ensures text stays on top */
}

.cutting-edge-description {
  font-size: 2rem;
  margin-bottom: 40px;
  color: whitesmoke;
  z-index: 1;
}

.cutting-edge-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  z-index: 1; /* Ensures list items are above the video */
}
.cutting-edge-video-background {
  visibility: visible;
  position: absolute; /* Ensure video is positioned behind the content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the section */
  z-index: -1; /* Keeps the video in the background */
}

.cutting-edge-list li {
  background-color: #202121;
  border-radius: 50%;
  width: 249px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(80, 24, 148, 0.4);
  text-align: center;
  color: #16878f;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.cutting-edge-list li:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(57, 12, 146, 0.6);
}

.cutting-edge-list li strong {
  font-family: "CALISTO MT", serif;
  color: #0975e1;
  font-weight: bolder;
}

.cutting-edge-list li p {
  font-family: 'Source Sans Pro', sans-serif;
  color: #d3d3d3;
  font-size: 1rem;
}

/* Contact Form Fields */
.contact-form input,
.contact-form textarea {
  font-family: "CALISTO MT", serif;
  display: block; /* Ensure elements take the full width */
  width: 100%; /* Full width */
  max-width: 100%; /* Prevent overflow */
  padding: 12px; /* Comfortable padding */
  margin-bottom: 15px; /* Space between fields */
  border: 1px solid #ccc; /* Neutral border */
  border-radius: 7px; /* Rounded corners */
  font-size: 1rem; /* Uniform font size */
  box-sizing: border-box; /* Include padding in width */
  background-color: #fff; /* White background */
  transition: all 0.3s ease; /* Smooth animations */
}

/* Input Field Focus State */
.contact-form input:focus,
.contact-form textarea:focus {
  outline: none; /* Remove default outline */
  border-color: #004080; /* Highlight border color on focus */
  box-shadow: 0 0 8px rgba(0, 64, 128, 0.5); /* Subtle glow effect */
}

/* Textarea Specific Styling */
.contact-form textarea {
  height: 100px; /* Optimal height */
  resize: vertical; /* Allow resizing only vertically */
}
.page-contact .info-box, .page-contact .contact-form {
  height: auto; /* Let content define the height initially */
  flex-grow: 1; /* Equalizes the height by flex */
}



/* Submit Button */
.contact-form button {
  background-color: #004080; /* Button background color */
  color: white; /* White text for contrast */
  padding: 8px 8px; /* Adequate padding */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Uniform font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
  font-family: "CALISTO MT", serif;
}

.contact-form button:hover {
  background-color: #002d5a; /* Darker shade on hover */
  transform: translateY(-2px); /* Lift button on hover */
}
.page-contact .info-box, .page-contact .contact-form {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure both boxes take full available height */
  justify-content: space-between; /* Align the contents evenly */
}
.page-contact .contact-form {
  height: auto; /* Allow the height to adjust based on content */
  max-height: 500px; /* Set a max height */
  overflow-y: auto; /* Add scrolling if content overflows */
}


/* Animation for Form Fields */
@keyframes fieldSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply Animation to Fields */
.contact-form input,
.contact-form textarea {
  animation: fieldSlideIn 0.5s ease-in-out;
}

/* Mobile View - Adjust Widths */
@media (max-width: 768px) {
  /* Ensure the SEO and Contact Form sections take the full width */
  .col-md-6 {
    width: 100% !important;  /* Force full width on mobile */
    padding-left: 0px !important;  /* Remove left padding */
    padding-right: 0px !important;  /* Remove right padding */
  }

  /* SEO Box */
  .info-box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: -17px;
    
    
  }
  
  .contact-form h2 {
    color: #013d79; /* Blue color for the heading */
    font-size: 1.8rem !important;
    
  }

  /* Contact Form */
  .contact-form {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  /* Form fields should be full width on mobile */
  .contact-form input,
  .contact-form textarea {
    width: 100%;  /* Ensure inputs fill the width */
    padding: 15px;  /* Add padding for comfort */
    font-size: 14px;
    margin-bottom: 15px; /* Space between fields */
    box-sizing: border-box;  /* Ensure padding is included in width */
  }

  .contact-form button {
    width: 100%;  /* Button takes full width */
    padding: 18px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px; /* Ensure there's space above the button */
  }

  /* Text and heading adjustments for better appearance */
  .digital-marketing-heading {
    font-size: 2.4rem;
    margin-top: -51px;  /* Adjusted for mobile spacing */
  }

  /* Ensure the text container is justified and looks well on mobile */
  .text-center.mb-5 {
    margin-top: -41px;
    text-align: justify;
    margin-left: -1rem;  /* Increase left margin */
    margin-right: -1rem;  /* Increase right margin */
    padding: 10px;  /* Adjust padding for mobile */
  }
}

/* For Larger Screens (Laptops and Desktops) */
@media (min-width: 1200px) {
  /* Ensure the container doesn't shrink */
  .contact-form {
    position: relative;
    top: 0; /* Reset the position adjustment */
    width: 100%;
    padding: 2rem 4rem; /* Increase padding on larger screens */
  }
  

  /* Adjust the heading font sizes for larger screens */
  .contact-form h2 {
    font-size: 2.5rem; /* Larger heading size for bigger screens */
  }

  /* Adjust the navbar padding and height for larger screens */
  .navbar {
    padding: 10px 20px; /* Adjust padding */
    height: auto; /* Adjust based on content */
  }

  /* Adjust the digital marketing background image and padding for larger screens */
  .digital-marketing-page {
    padding: 70px 0; /* Increase top and bottom padding */
  }

  /* Adjust the size of the cutting-edge section for larger screens */
  .cutting-edge-container {
    padding: 60px 40px; /* Increase padding */
    font-size: 1.2rem; /* Adjust font size for better readability */
  }

  /* Ensure the info-box containers in the .row are full-width on large screens */
  .row .col-md-4 .info-box {
    width: 100%; /* Ensures boxes take full width */
  }

  /* Adjust the size of the cutting-edge list items for larger screens */
  .cutting-edge-list li {
    width: 250px; /* Increase size of the circular items */
    height: 250px; /* Match height with width */
  }
}

/* Further Adjustment for Very Large Screens (extra-wide screens) */
@media (min-width: 1500px) {
  .contact-form {
    width: 80%; /* Set width to 80% of the screen for very large screens */
    margin: 0 auto; /* Center-align the form */
    padding: 3rem 6rem; /* More padding for extra-large screens */
  }

  /* Adjust the padding of the navbar */
  .navbar {
    padding: 15px 30px; /* Increase navbar padding */
  }

  /* Adjust the background of the digital marketing page */
  .digital-marketing-page {
    padding: 90px 0; /* Further padding adjustments */
  }

  /* Adjust cutting-edge container font size */
  .cutting-edge-container {
    font-size: 1.4rem; /* Adjust for readability on large screens */
  }

  /* Increase the font-size of headings */
  .contact-form h2 {
    font-size: 3rem; /* Adjust heading size */
  }
}