/* Main Container for Homepage */
.homepage-container {
  background-color: rgb(246, 244, 242) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #f6efef;
  overflow: hidden; /* Prevent scrolling */
  z-index: 1; /* Ensure content stays on top of the video */
  padding-top: 1rem;
  margin-top: 2.7rem;
  justify-content: flex-start;
}
 /* Adjust header spacing and alignment */
 .navbar {
  z-index: 3; /* Ensure it stays above the video */
  position: relative; /* Prevent overlapping issues */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a translucent background */
  width: 100%;
  
}
.whatsapp-icon {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 60px; /* Set the size of the icon */
  height: 60px;
  cursor: pointer;
}

.whatsapp-icon img {
  width: 100%; /* Fill the container */
  height: 100%;
  border-radius: 50%; /* Ensures the image itself is circular */
  object-fit: cover; /* Ensures the image scales properly */
}


/* Background Video */
.homepage-container .background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1; /* Place the video behind content */
  pointer-events: none;
}

body, html {
  margin: 0;
  padding: 0;
}

/* Centered Text Section */
.text-center {
  z-index: 2;
  text-align: center;
  margin-bottom: 2rem;
}

/* Homepage Heading */
.homepage-heading {
  font-family: "Great Vibes", cursive;
  font-weight: bold;
  font-size: 4vw; /* Adjust size for desktop */
  color: #f1eeee;
  
  margin-top: 6rem;
  margin-bottom: 5.5rem;
}

/* Homepage Description */
/* Homepage Description */
.homepage-description {
  font-size: 1.3rem; /* Adjust font size as needed */
  line-height: 2; /* Space between lines */
  font-family: "CALISTO MT", serif;/* Font style */
  color: rgb(244, 244, 248); /* Text color */
  text-align: center; /* Center-align the text */
  width: 64%; /* Adjust width for proper layout */
  margin: 0 auto; /* Horizontally center within the container */
  position: relative; /* Position adjustment */
  top: 50%; /* Vertically center */
  transform: translateY(-50%); /* Precise vertical centering */
  text-indent: 2rem; /* Add indentation to the first line */
  margin-bottom: 2rem;
}




/* Highlight Blinking Effect */
.highlight {
  animation: blinkText 1.5s infinite;
}

@keyframes blinkText {
  0%, 100% {
    color: #ad984b;
  }
  50% {
    color: #c7bb96;
  }
}

/* Buttons Section */
.btn-custom {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 13%;
}

.btn-custom-white {
  background-color: #f4ebeb;
  color: #333;
}

.btn-custom-white:hover {
  background-color: #f2f2f2;
  color: #000;
}

.btn-custom-orange {
  background-color: #ffa500;
  color: #fff;
}

.btn-custom-orange:hover {
  background-color: #ff8c00;
}

/* Responsive Styling for Mobile View */
@media (max-width: 768px) {
  /* Adjust container padding and margins */
  .homepage-container {
    padding-top: 0rem;
    margin-top: 1.4rem;
    position: relative; /* Ensure positioning for absolute elements */
  }

  /* Heading adjustments */
  .homepage-heading {
    font-size: 2.9rem;
    margin-bottom: 12rem;
    position: absolute; /* Fix heading in place */
    top: 4%; /* Position the heading towards the top */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    font-weight: bold;
  }

  /* Description adjustments */
  .homepage-description {
    font-size: 1.3rem;
    line-height: 1.6;
    width: 93%; /* Fit content to smaller screens */
    text-indent: 1rem; /* Smaller indentation */
    position: absolute; /* Fix description position */
    top: 38%; /* Position description below heading */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    text-align: center; /* Align text in the center */
    
  }

  /* Adjust the layout of buttons in homepage-buttons */
  .homepage-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically in column */
    justify-content: center; /* Center-align buttons */
    gap: 2rem; /* Space between buttons */
    padding-top: 80px; /* Add padding to prevent overlap with navbar */
    align-items: center;
    position: absolute; /* Fix buttons in place */
    top: 55%; /* Position buttons below description */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
  }

  .btn-custom {
    width: 20rem; /* Adjust button size dynamically */
    font-size: 1rem; /* Keep text readable */
    padding: 0.8rem; /* Adequate padding */
    margin-top: 0rem;
    align-items: center;
  }
}
/* Responsive Styling for 1200px Screens Only */
@media (width: 1200px) {
  /* Adjust container padding and margins for 1200px screens */
  .homepage-container {
    padding-top: 0rem;
    margin-top: 2rem;
  }

  /* Heading adjustments for 1200px screens */
  .homepage-heading {
    font-size: 2.5rem; /* Slightly larger heading for 1200px screens */
    margin-bottom: 9rem; /* Adjust margin to keep balance */
  }

  /* Description adjustments for 1200px screens */
  .homepage-description {
    font-size: 1.1rem; /* Slightly larger font size for better readability */
    line-height: 1.8;
    width: 80%; /* Increase the width to fill the screen better */
    text-indent: 1rem;
    top: 45%; /* Position description a bit lower */
  }

  /* Buttons adjustments for 1200px screens */
  .homepage-buttons {
    display: flex;
    flex-direction: row; /* Align buttons in a row for better balance on 1200px screens */
    justify-content: center; /* Center-align buttons */
    gap: 2rem; /* Space between buttons */
    padding-top: 80px; /* Adjust padding to prevent overlap */
    align-items: center;
    position: absolute; /* Keep buttons in a fixed position */
    top: 60%; /* Position buttons below the description */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
  }

  .btn-custom {
    width: 18rem; /* Slightly smaller buttons for better alignment */
    font-size: 1rem; /* Keep text readable */
    padding: 0.8rem; /* Adequate padding */
    margin-top: 0rem;
    align-items: center;
  }
}

/* Mobile Viewport: Large Screens (iPhone Plus, Larger Android) */
@media (max-width: 1024px) {
  /* Adjustments for larger mobile screens */
  .container {
    width: 85%; /* Slightly reduced width for larger screens */
  }

  .text-center {
    font-size: 1.2rem; /* Slightly larger text */
    padding: 2rem;
  }

  .navbar {
    font-size: 1.1rem;
  }
}
/* Adjust for mobile using media queries */
@media (max-width: 768px) {
  .whatsapp-icon {
    right: 10px; /* Adjusted for mobile */
    bottom: 10px; /* Adjusted for mobile */
  }

  .whatsapp-img {
    width: 50px; /* Smaller size for mobile */
    height: 50px;
  }
}

@media (min-width: 1440px) {
  .homepage-heading {
    font-size: 4rem;
  }
  .homepage-description {
    width: 50%;
  }

  .homepage-buttons {
    display: flex;
    gap: 3rem;
    margin-top: 3rem;
  }
}
