
/* Web Development Intro Section */
.web-dev-intro h1 {
    font-family: "CALISTO MT", serif;
    color: #013d79;
    margin-bottom: 20px;
    margin-top: 0rem;
  }
  .row {
    display: flex;
  }
  
  .col-md-7, .col-md-5 {
    display: flex;
    flex-direction: column;
    
  }
  
  .web-dev-intro p {
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-size: 16px; /* Optimal for both mobile and desktop */
  line-height: 1.5;
  }
  
  /* Service Boxes */
  .box {
    background-color: #f8f9fa;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .box h3 {
    font-family: "CALISTO MT", serif;
    color: #013d79;
  }
  
  .box p {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
    font-size: 16px; /* Optimal size for both mobile and desktop */
    line-height: 1.6; /* Ensures readability */
    color: #333;
  }
  
  .service-img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    animation: blinkAnimation 1s infinite;
  }
  
  @keyframes blinkAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Contact Form Styling */
  .contact-form {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 95%; /* Ensure the contact form matches the height of the left box */
    max-width: 500px;
  }
  
  .contact-form h4 {
    font-family: "CALISTO MT", serif;
    color: #013d79;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: medium;

  }
  
  .contact-form .form-control {
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .contact-form .message-box {
    height: 150px; /* Increase the size of the message box */
  }
  
  .contact-form button {
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    background-color: #013d79;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    display: block; /* Center align the button */
  }
  
  /* Cutting-Edge Technology - Curved Boxes */
.curve-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
  
  /* Individual curve boxes */
  .curve {
    background-color: #070707;
    padding: 30px;
    border-radius: 25px;
    width: 18%;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    
    display: flex;
    flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Distribute space between the heading and text */
  height: 300px;
  }
  
  /* Cutting-Edge Technology Section Heading */
.cutting-edge h3 {
    font-family: "CALISTO MT", serif; /* Set the font style to Galliard BT */
    color: #0554a2; /* Blue color for the heading */
    text-align: center; /* Center align the heading */
    margin-bottom: 20px; /* Add some space below the heading */
    font-size: 3rem; /* Adjust font size as needed */
    font-weight: bolder;
  }
  
  /* Cutting-Edge Technology Section Text */
  .cutting-edge p {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
  font-size: 16px; /* Optimal size for both mobile and desktop */
  line-height: 1.6; /* Ensures readability */
    color: #000000; /* Black color for the paragraph text */
    text-align: center; /* Center align the paragraph text */
    font-size: 1.1rem; /* Adjust font size for readability */
    margin-top: 10px; /* Space above the paragraph */
    margin-bottom: 30px; /* Space below the paragraph */
  }
  


  /* Individual curve boxes */
 /* Add different background images to each box with a light overlay */
 .curve:nth-child(1),
 .curve:nth-child(2),
 .curve:nth-child(3),
 .curve:nth-child(4),
 .curve:nth-child(5) {
   
   background-position: center; /* Centers the background image */
   position: relative; /* Allows for text positioning on top */
   
   padding: 20px; /* Optional: Adjust padding as needed */
   /* Prevent any overflow of background image */
   font-weight: bolder;
 }

/* Add a light overlay effect to make the images appear lighter */
.curve:nth-child(1)::before,
.curve:nth-child(2)::before,
.curve:nth-child(3)::before,
.curve:nth-child(4)::before,
.curve:nth-child(5)::before {
  content: ""; /* Add content for pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 14, 14, 0.4); /* Light white overlay */
  z-index: 1; /* Ensure overlay appears above the background but below the text */
}

/* Optional: Add a shadow to text for better visibility */
.curve h2,
.curve p,
.curve h5 {
  text-shadow: 2px 2px 4px rgba(15, 15, 15, 0.5); /* Add text shadow for better contrast */
}
.curve h2 {
  font-size: 3rem;
}




  /* Hover Effect - Move the box up and down */
  .curve:hover {
    transform: translateY(-10px); /* Box moves up */
    background-color: #141618; /* Change background color on hover */
    color: rgb(25, 24, 24); /* Change text color */
  }
  
  .curve:hover h5 {
    font-family: "CALISTO MT", serif; /* Change heading font style */
    color: #e0d610; /* Heading color on hover */
    font-weight: bold;
  }
  
  .curve:hover p {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
    font-size: 16px; /* Optimal size for both mobile and desktop */
    line-height: 1.6; /* Ensures readability */
    color: #eae7ef; /* Text color on hover */
    font-weight: bolder;
  }
  
  /* Heading inside each curve box */
  .curve h5 {
    font-family: "CALISTO MT", serif;
    color: #e8fc13; /* Initial heading color */
    margin-bottom: 10px;
    font-size: 1.3rem; /* Adjust font size */
    font-weight: bolder;
  }
  
  /* Paragraph text inside each curve box */
  .curve p {
    font-family: 'Poppins', sans-serif; /* Apply Poppins font */
    color: #fff7f7; /* Initial text color */
    margin-top: 0; /* Remove top margin */
    flex-grow: 0.5; /* Allow the paragraph to take up available space */
  margin-bottom: 0; /* Remove bottom margin */
  font-weight: bolder;
  font-size: medium;
  
  }
  

  
  /* Mobile View: Bootstrap Media Queries */
  @media (max-width: 768px) {
    .curve-boxes {
      flex-direction: column; /* Stack boxes vertically on small screens */

    }
    body {
      font-size: 16px; 
    }
    .web-dev-intro h1 {
      font-size: 2.3rem;
      margin-top: -2rem;
    }
    .web-dev-intro p {
      margin-top: -2.8em;
      margin-left: 0; /* Make sure it aligns with the start */
      margin-right: 0; /* Ensure it ends correctly without cutting off */
      padding-left: 0; /* Ensure padding is consistent */
      padding-right: 0; 
    }
  
    .curve {
      width: 100%; /* Make each box take full width on mobile */
      margin-bottom: 20px;
    }
  
    .contact-form {
      margin-top: 30px;
    }
    .curve-boxes {
      flex-direction: column; /* Stack boxes vertically on small screens */
      align-items: center;
    } 
  
    .curve {
      width: 80%; /* Decrease width of the curve boxes on mobile */
      height: 250px; /* Decrease height of the curve boxes */
      margin-bottom: 20px;
    }
  
    .curve h5 {
      font-size: 1.5rem; /* Increase the font size of the heading for mobile */
    }
  
    .contact-form {
      margin-top: 30px;
    }
  
    .service-img {
      width: 100%;
      height: auto;
    }
  

  }

  /* Larger Laptops (Greater than 1600px) */
@media (min-width: 1600px) {
  .row {
    display: flex;
    justify-content: space-between;
  }

  .col-md-7, .col-md-5 {
    flex-direction: column;
  }

  .contact-form {
    max-width: 700px; /* Increase the width of the contact form for larger screens */
  }

  .curve {
    width: 22%; /* Increase the width of the curve boxes */
  }

  .service-img {
    max-width: 100%;
    height: auto;
  }

  /* Additional adjustments for larger screens */
  .web-dev-intro h1 {
    font-size: 3rem; /* Adjust font size for better readability on large screens */
  }

  .web-dev-intro p {
    font-size: 1.2rem; /* Increase paragraph font size for better readability */
  }

  .box {
    padding: 30px; /* Increase padding for service boxes */
  }

  .curve-boxes {
    gap: 30px; /* Increase gap between curve boxes */
  }

  /* Optional: Adjust font sizes for better readability */
  .curve h5 {
    font-size: 1.5rem;
  }

  .curve p {
    font-size: 1.1rem;
  }
}
