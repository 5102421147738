.App {
  text-align: center;
}
/* App.css or your global CSS file */

/* Ensure the body has the padding to avoid content being hidden behind the fixed navbar */
body {
  background-color: white;
  padding-top: 40px; /* Adjust based on your navbar height */
  margin: 0;
}

/* Ensure the Contact Us section has the proper margin to not go behind the navbar */
#contact-us {
  scroll-margin-top: 80px; /* Adjust based on your navbar height */
}

/* Styling for the navbar if it's fixed at the top */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  background-color: white; /* Apply white background globally */
}
.whatsapp-icon {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 60px; /* Set the size of the icon */
  height: 60px;
  cursor: pointer;
}

.whatsapp-icon img {
  width: 100%; /* Fill the container */
  height: 100%;
  border-radius: 50%; /* Ensures the image itself is circular */
  object-fit: cover; /* Ensures the image scales properly */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 